import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { api } from "@iso/config/site.config";

import actions from "./actions";

const getResultsApi = async (searchText) => {
  if (searchText.length >= 4) {
    let apiParams = {
      s: searchText,
    };
    return await api
      .get("/search/object", { params: apiParams })
      .then((response) => response.data)
      .then((response) => {
        return response;
      })
      .catch((error) => ({ error }));
  }
};

export function* getResultsRequest() {
  yield takeEvery(actions.GET_RESULTS, function* (payload) {
    const result = yield call(getResultsApi, payload.searchText);
    if (result && result.success) {
      yield put({
        type: actions.GET_RESULTS_SUCCESS,
        payload: result,
      });
    } else {
      yield put({
        type: actions.GET_RESULTS_FALSE,
        searchText: payload.searchText,
      })
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getResultsRequest)]);
}
