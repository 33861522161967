import React from 'react';
import LoaderComponent from './loader.style';
import loader_gif from "@iso/assets/images_main/lalp_gif.gif";

export default (props) => (
  <LoaderComponent className={props.className}>
    <img 
      src={loader_gif}
      width="80"
      height="80"
      alt=""
    />
    {/*
    <svg className="isoContentLoader" viewBox="0 0 50 50">
      <circle
        className="isoContentLoaderCircle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="3.6"
      />
    </svg>
    */}
  </LoaderComponent>
);
