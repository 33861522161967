const theme = {};

let colors ={
  color0:'transparent',
  color1:"#ffffff",     
  color2:"#000000",
  color3:"#2195f2",
  color4:"#032d56",
  color5:'#06182d',
  color6:'#041e42',
  color7:'#2195f2',
  color8:'#bcbcbc',
  color9:'#064059',
  color10:'#666666',
  color11:'#333333',
  color12:'#1D334D',
  color13:'#e5e5e5',   //colores diferente para 24e y m360
  color14:'rgba(255,255,255,0.4)',
  color15:'rgba(2,1,12,0.4)',
}

theme.invertido = ""  //agregar invertido como parámetro

theme.palette = {
  //color loading
  primary: ['green'],
  secondary: ['red'],

  //tipografia
  colorText:[colors.color1],
  colorTitle:[colors.color1],
  colorButtonMain:[colors.color0],
  colorLink:[colors.color4],
  colorBulletTabs:[colors.color3],
  colorTextButtonMain2:[colors.color4],

  //sidebar
  bgSidebar:[colors.color4],
  colorLinkSidebar:[colors.color3],
  colorTextSidebar:[colors.color1],

  //Content
  bgContent:[colors.color5],
  bgLayout:[colors.color4],

  //Topbar
  bgTopbar:[colors.color6],

  //ContentBlue
  bgContentBlue:[(theme.invertido === "invertido" ? colors.color1:colors.color5)],
  colorContentBlue:[(theme.invertido === "invertido" ? colors.color2:colors.color1)],
  bgContentBlueInverso:[theme.invertido === "invertido" ? colors.color5: colors.color15],
  colorContentBlueInverso:[colors.color1],

  //video
  bgSidebarVideo: [colors.color6],
  borderSidebarVideo: [colors.color3],
  colorTabsVideo: [colors.color1],
  bgItemTabsVideo:[colors.color7],
  bgEmbedContainerVideo:[colors.color2],
  
  //login
  colorTextLogin:[colors.color1],
  bgTextLogoLogin:[colors.color13],
  colorTextLogoLogin:[colors.color1],
  colorTextButtonLogin:[colors.color1],
  colorBorderButtonLogin:[ (theme.invertido === "invertido" ? colors.color1:colors.color1)],
  bgButtonLogin:[colors.color0],
  colorInputLogin:[colors.color1],
  colorBorderInputLogin:[colors.color14],

  //course
  bgCourse:[colors.color5],
  bgTopbarCourse:[colors.color6],
  bgBack:[colors.color4],
  colorTextCourse:[colors.color1],
  colorButtonCourse:[colors.color3],
  bgApplyNow:[colors.color6],
  colorTextApplyNow:[colors.color2],
  colorTextApplyNowParrafo:[colors.color10],
  colorBorderApply:[colors.color8],

  //faq
  colorTextFaq:[colors.color1],

  //miperfil
  bgProfile:[(theme.invertido === "invertido" ? colors.color8:colors.color4)],
  colorTitleProfile:[(theme.invertido === "invertido" ? colors.color4:colors.color1)],
  colorLineaProfile:[colors.color14],
  linkFilesProfile:[colors.color6],

  
  //progress curso
  colorTitleProgress:[(theme.invertido === "invertido" ? colors.color11:colors.color1)],
  colorLineaProgress:[(theme.invertido === "invertido" ? colors.color11:colors.color10)],
  bgProgress:[(theme.invertido === "invertido" ? colors.color1:colors.color5)],
  colorSmallProgramasCurso:[colors.color10],
  bgCollapseProgress:[colors.color14],
  colorTitleCollapseProgress:[colors.color3],

  //Notificacion
  colorNotificacion:[colors.color1],
  bgNotificacion:[colors.color3],

  //metas personales
  bgMetasPersonales:[colors.color5],
  colorMetasPersonales:[colors.color1],
  colorIconsMetasPersonales:[colors.color3],
  colorTasksMetasPersonales:[colors.color10],

  //Cargas y Herramientas
  colorCargasHerramientas:[colors.color3],
  bgCargasHerramientas:[colors.color1],

  //calendario
  colorLineaCalendario:[colors.color10],
  bgItemCalendario:[colors.color7],
  colorItemCalendario:[colors.color1],
  colorBulletSquareItem:[colors.color3],

  //modal
  bgModal:[colors.color6],
  colorModal:[colors.color1],
  bgConfirmModal:[colors.color3],

  //lectura 1
  colorLectura1:[colors.color1],
  colorFillLectura:[colors.color3],

  //lectura 2
  colorLectura2:[colors.color1],
  bgLectura2:[colors.color1],

  //editor de texto
  colorEditorTexto:[colors.color2],

  //programas - curso
  bgProgramasCurso:[(theme.invertido === "invertido" ? colors.color8:colors.color1)],
  colorSmallProgramasCurso:[colors.color10],
  colorContentTabsProgramasCurso:[(theme.invertido === "invertido" ? colors.color2:colors.color4)],
  colorTabsProgramasCurso:[(theme.invertido === "invertido" ? colors.color10:colors.color4)],
  colorTabsActiveProgramasCurso:[(theme.invertido === "invertido" ? colors.color4:colors.color4)],
  bgTabsActiveProgramasCurso:[colors.color1],
  colorBorderTabsProgramasCurso:[colors.color4],
  bgTabsProgramasCurso:[colors.color13],

  //programas - proyectos
  colorDiagramaProgramasProyecto:[colors.color1],
  bgProgramasProyecto:[colors.color7],
  bgTabsProgramasProyecto:[colors.color4],
  colorTabsProgramasProyecto:[colors.color3],
  colorTabsActiveProgramasProyecto:[colors.color1],
  colorSmallProgramasProyecto:[colors.color2],

  colorTabsProyecto:[colors.color14],
  colorTabsHoverProyecto:[colors.color1],
  colorTabsBorderProyecto:[colors.color3],
  bgContentBlueProyecto:[colors.color4],
  colorSmallProyecto:[colors.color1],
  colorTitleProyecto:[colors.color1],

  colorResumenOds:[colors.color1],
  bgResumenOds:[colors.color8],

  //item - proyectos
  colorItemProyectos:[(theme.invertido === "invertido" ? colors.color2:colors.color1)],
  bgItemProyectos:[(theme.invertido === "invertido" ? colors.color1:colors.color5)],
  titleItemProyectos:[(theme.invertido === "invertido" ? colors.color4:colors.color1)],

  //Editar contenido
  colorEditTitle:[colors.color1],

  //Save Editar
  colorSaveEdit:[colors.color3],
  fillSaveEdit:[colors.color3],

  //colores adicionales
  colorWhite:[colors.color1],
  colorWhiteVar1:[colors.color14],
  colorWhiteVar2:[colors.color14],
  colorWhiteVar3:[colors.color14],
  colorWhiteVar4:[colors.color14],
  colorSmall:[colors.color10],
  colorGray1:[colors.color10],
  colorGray2:[colors.color10],
  colorGray3:[colors.color9],
  colorGray4:[colors.color11],
  colorGray5:[colors.color10],
  colorGray6:[colors.color11],
  colorGray7:[colors.color10],
  colorGray8:[colors.color3],
  colorGray9:[colors.color10],
  colorBlack:[colors.color2],
  colorBlackVar1:[colors.color11],
  colorBlackVar2:[colors.color12],
  colorBlackVar3:[colors.color11],
  colorBlackVar4:[colors.color12],
  colorBlackVar5:[colors.color8],
  transparent:[colors.color0],
}

theme.fonts = {
  primary: 'Roboto, sans-serif',
  secondary: 'Poppins, sans-serif',
  size:'15px',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  tipo: 'invertido'
};

export default theme;
