import actions from "./actions";

const initState = {
  searchText: '',
  users: [],
  projects: [],
  institutions: [],
  terms: [],
  loading: false,
}

function searchReducer(state = initState, action) {
  switch(action.type) {
    case actions.GET_RESULTS:
      return {
        ...state,
        searchText: action.searchText,
        loading: true,
      };
    case actions.GET_RESULTS_SUCCESS:
      return {
        ...state,
        searchText: action.payload.searchText,
        users: [...action.payload.users],
        projects: [...action.payload.projects],
        institutions: [...action.payload.institutions],
        terms: [...action.payload.terms],
        loading: false,
      };
    case actions.GET_RESULTS_FALSE:
      return {
        ...state,
        loading: false,
        users: [],
        projects: [],
        institutions: [],
        terms: [],
        searchText: action.searchText,
      };
    default:
      return state;
  }
}

export default searchReducer;
