import { Map } from 'immutable';
import { useLocation } from "react-router-dom";

export function clearToken() {
  localStorage.removeItem('id_token');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function setUserData(token, userName, profilePicture, twoStepVerification) {
  localStorage.setItem('id_token', token);
  localStorage.setItem('user_name', userName);
  localStorage.setItem('profile_picture', profilePicture);
  localStorage.setItem('two_step_verification', twoStepVerification);
}

export function clearUserData() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('user_name');
  localStorage.removeItem('profile_picture');
}

export function getUserData() {
  try {
    const idToken = localStorage.getItem('id_token');
    const userName = localStorage.getItem('user_name');
    const profilePicture = localStorage.getItem('profile_picture');
    const twoStepVerification = localStorage.getItem('two_step_verification');
    return new Map({ idToken, userName, profilePicture, twoStepVerification });
  } catch (err) {
    clearUserData();
    return new Map();
  }
}

export function setCourseInfo(courseIcon, courseIconHorizontal) {
  localStorage.setItem('course_icon', courseIcon);
  localStorage.setItem('course_icon_horizontal', courseIconHorizontal)
}

export function getCourseInfo() {
  try {
    const courseIcon = localStorage.getItem('course_icon');
    const courseIconHorizontal = localStorage.getItem('course_icon_horizontal');
    return new Map({ courseIcon, courseIconHorizontal });
  } catch (err) {
    clearCourseInfo();
    return new Map();
  }
}

export function clearCourseInfo() {
  localStorage.removeItem('course_icon');
  localStorage.removeItem('course_icon_horizontal');
}

export function truncate (str, charNumber = 50) {
  str = str?str:'';
  return str.length > charNumber ? str.substring(0, charNumber-3) + "..." : str;
}

export function getJobFunctionInstitution ( job_function, institution) {
  return (institution && job_function) ?
  `${job_function} at ${institution}` :
    job_function ? job_function : institution
}

export function removeLabels (arrValues) {
  let result = arrValues.map(a => a.key);
  return result
}

export function getKey (arrObj) {
  let result = arrObj.key;
  return result
}

export function getObjectTaxRelKeys (_object, selectFields) {
  for (let field in selectFields) {
    var fieldname = selectFields[field];
    var fieldValue = _object[fieldname];
    if (fieldValue && typeof fieldValue === 'object' && fieldValue.length !== undefined) {
      fieldValue = removeLabels(fieldValue);
    } else if (fieldValue && typeof fieldValue === 'object') {
      fieldValue = getKey(fieldValue);
    }
    _object[fieldname] = fieldValue;
  }
}

export function compareByAlph (a, b) {
  if (a > b) {
    return -1; 
  } 
  if (a < b) {
    return 1; 
  } 
  return 0;
}

export function SetFrom () {
  const location = useLocation();
  localStorage.setItem('from', location.pathname);
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = number => {
    return number > 1 ? 's' : '';
  };
  const number = num => (num > 9 ? '' + num : '0' + num);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days);
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const month = months[givenTime.getUTCMonth()];
        const day = number(givenTime.getUTCDate());
        return `${day} ${month}`;
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  } else if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}
