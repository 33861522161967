import { createGlobalStyle } from 'styled-components';
import { palette, font} from 'styled-theme';
import 'antd/dist/antd.css';
import icon_circle_02 from '@iso/assets/images/icon_circle_02.svg';
import icon_circle_01 from '@iso/assets/images/icon_circle_01.svg';


const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    //background: ${font('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/*Agregado*/
html 
{
  body
  {
    font-size:${font('size', 0)};
    color:${palette('colorText', 0)};
    
    h1,h2,h3,h4,h5,h6
    {
      font-family:${font('primary', 0)};
      color:${palette('colorTitle', 0)};
      font-weight:500;
    }
    h1
    {
      margin-bottom:20px;
    }
    h2
    {
      font-size:18px;
      margin-bottom:30px;
    }
    h3
    {
      font-size:17px;
      margin-bottom:13px;
      a{
        color:${palette('colorTitle', 0)};
        font-family:${font('secondary', 0)};
        &:hover,&:active,&:focus{
          color:${palette('colorTitle', 0)};
        }
      }
    }
    h4{
      margin-bottom:13px;
      font-size:15px;
    }
    h5
    {
      margin-bottom:10px;
    }
    h6
    {
      margin-bottom:10px;
    }
    p
    {
      margin-bottom:25px;
      &:last-child
      {
        margin-bottom:0px;
      }
    }
    a{
      display:inline-block;
      color:${palette('colorLink', 0)};
      &:hover, &:active, &:focus
      {
        color:${palette('colorLink', 0)};
      }
    }
    small
    {
      color:${palette('colorText', 0)}; 
      font-size:14px;
    }

    .button-main
    {
      border:2px solid ${palette('colorText', 0)};
      padding:3.5px 18px 2.5px 18px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      color:${palette('colorWhite', 0)};
      background-color: ${palette('colorButtonMain', 0)};
      border-radius: 30px;
      font-size:14px;
      &:hover,&:active,&:focus{
        color:${palette('colorWhite', 0)};
      }
      @media only screen and (max-width: 575px) 
      { 
        padding:3.5px 10px 2.5px 10px;
        font-size:13px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) 
      {
        padding:3.5px 10px 2.5px 10px;
        font-size:13px;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) 
      {
        padding:3.5px 18px 2.5px 18px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) 
      {
        padding:3.5px 15px 2.5px 15px;
        font-size:14px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1399px) 
      {
        padding:3.5px 15px 2.5px 15px;
        font-size:14px;
      }
      @media only screen and (min-width: 1400px) and (max-width: 1599px) 
      {
        padding:3.5px 17px 2.5px 17px;
      }
    }

    .button-main.ant-btn{
      &:hover,&:active,&:focus{
        background:${palette('colorButtonMain', 0)};
        border-color:${palette('colorText', 0)};
      }
    }

    .button-main-2, .ant-btn.button-main-2
    {
      color:${palette('colorTextButtonMain2', 0)};
      border:2px solid ${palette('colorTextButtonMain2', 0)} !important;
      &:hover,&:active,&:focus{
        color:${palette('colorTextButtonMain2', 0)};
      }
    }

    .ant-btn.button-main-small
    {
      border:1px solid ${palette('colorButtonMain', 0)};
      padding:3px 18px 2.5px 18px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      color:${palette('colorWhite', 0)};
      background-color: ${palette('colorButtonMain', 0)};
      border-radius: 30px;
      &:hover,&:active,&:focus{
        color:${palette('colorWhite', 0)};
      }
      @media only screen and (max-width: 575px) 
      { 
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) 
      {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) 
      {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) 
      {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) 
      {
        font-size: 13.5px;
        padding:1px 13px 1.5px 13px;
      }
    }

    .button-main-small
    {
      border:1px solid ${palette('colorButtonMain', 0)};
      padding:3px 18px 2.5px 18px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      color:${palette('colorWhite', 0)};
      background-color: ${palette('colorButtonMain', 0)};
      border-radius: 30px;
      &:hover,&:active,&:focus{
        color:${palette('colorWhite', 0)};
      }
      @media only screen and (max-width: 575px) 
      {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px) 
      {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 768px) and (max-width: 991px) 
      {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) 
      {
        font-size: 13px;
        padding:1px 13px 1.5px 13px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) 
      {
        font-size: 13.5px;
        padding:1px 13px 1.5px 13px;
      }
    }

    button.button-main-small
    {
      background-color: ${palette('colorButtonMain', 0)};
      border:1px solid ${palette('colorWhite', 0)};
      border-radius: 30px !important;
      &:hover,&:active,&:focus{
        border:1px solid ${palette('colorWhite', 0)} !important;
      }
      span{
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          text-transform: uppercase;
          color:${palette('colorWhite', 0)};
          &:hover,&:active,&:focus{
            color:${palette('colorWhite', 0)};
          }
          &:hover,&:active,&:focus{
            
          }
          @media only screen and (max-width: 575px) 
          {
            font-size: 13px;
            padding:1px 13px 1.5px 13px;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) 
          {
            font-size: 13px;
            padding:1px 13px 1.5px 13px;
          }
          @media only screen and (min-width: 768px) and (max-width: 991px) 
          {
            font-size: 13px;
            padding:1px 13px 1.5px 13px;
          }
          @media only screen and (min-width: 992px) and (max-width: 1199px) 
          {
            font-size: 13px;
            padding:1px 13px 1.5px 13px;
          }
          @media only screen and (min-width: 1200px) and (max-width: 1599px) 
          {
            font-size: 13.5px;
            padding:1px 13px 1.5px 13px;
          }
      }
      @media only screen and (max-width: 575px) 
      {
          padding: 0 15px !important;
      }   
    } 
    .isoLayoutContentWrapper
    {
      .page-content-tabs
      {
        padding:0px;
      } 

      ul
      {
        padding: 0 0 0 25px;
        margin-bottom: 25px;
        li
        {
          background-image: url(${icon_circle_02});
          background-repeat: no-repeat;
          background-size: 7px 7px;
          background-position-x: 0;
          background-position-y: 8px;
          padding: 0 0 0px 14px;
          margin-bottom: 10px;
        }
        &:last-child
        {
          margin-bottom: 0px;
        }
      }

      ol
      {
        margin-bottom:25px;
        li
        {
          margin-bottom: 10px;
          &:last-child
          {
            margin-bottom: 0px;
          }
        }
        &:last-child
        {
          margin-bottom: 0px;
        }
      }

      img{
        @media only screen and (max-width: 575px)
        {
          //width:100%;
          //height:auto;
        }
      }

      .ck-editor{
        .ck-editor__editable{
          ul{
            padding-left: 30px;
            margin-bottom:15px;
            li
            {
              background-image: inherit;
              list-style-type: disc;
              padding-left: 0px;
              margin-bottom:7px;
              &::before{
                content:none;
              }
            }
          }
          figcaption.ck-editor__editable{
            min-height: inherit !important;
          }
          @media only screen and (max-width: 575px)
          {
            padding-right: 30px;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) 
          {
            padding-right: 30px;
          }
        }

        .ck-dropdown__panel
        {
          @media only screen and (max-width: 575px)
          {
            width: 100%;
            transform: translateY(10px);
          }
          @media only screen and (min-width: 576px) and (max-width: 767px) 
          {
            width: 100%;
            transform: translateY(10px);
          }
        }
        .ck-list__item{
          &::before{
            content:none;
          }
        }

      }

      ul.ul-units
      {
        margin:0px;
        padding:0px;
        display: flex;
        width: 100%;
        li{
          position:relative;
          background-image: inherit;
          padding:0px;
          margin: 0px;
          width: 25%;  /*por que es entre 5 a 100/4=25% bullets, actualizar de acuerdo al numero de bullets*/ 
          &::after
          {
            content:' ';
            display: inline-block;
            background-color:${palette('colorGray1', 0)};
            height: 1px; 
            width: 92%;
            position: absolute;
            left:11px;
            top:50%;
            transform: translateY(-50%);  
          }
  
          &:last-child
          {
            width: 11px;
            &::after
            {
              width: 0px;
            }
          }
          span.span-svg-color{
            svg{
              circle{
                fill:${palette('colorLink', 0)};
              }
            }
          }
          span.title
          {
            position: absolute;
            top:25px;
            left:0px;
            font-size:12px;
            line-height:1em;
            width:70px;
          }
        }
      }

      ul.ul-tabs
      {
        padding:0px;
        margin:0px;
        padding-left:10px;
        li{
          position: relative;
          background-image: inherit;
          background-repeat: no-repeat;
          background-size: 7px 7px;
          background-position-x: 0px;
          background-position-y: 9px;
          padding:0px;
          margin:0px;
          padding:0px 0px 5px 22px;
          margin-bottom:18px;
          display: flex;
          &::after
          {
            content:' ';
            width: 1px;
            background-color: ${palette('colorGray1', 0)};
            height: 146%;
            left:3px;
            top:14px;
            position: absolute;
          }
          &::before{
            content:'\\00b7';
            //content:url(${icon_circle_01});
            width: 6px;
            height: 6px;
            font-size: 60px;
            color: ${palette('colorBulletTabs', 0)};
            display: inline-block;
            border-radius: 50%;
            vertical-align: middle;
            position: absolute;
            left: -4px;
            top: 0px;
          }
          span
          {
            margin-right: 3px;
          }
          &:last-child
          {
            &::after{
              height: 0%;
            }
          }
        }
        @media only screen and (max-width: 575px)
        {
          margin-bottom: 25px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
          margin-bottom: 40px;
        }
      }

      .ul-tabs-accordion-video
      {
        margin:0px;
        padding:0px;
        padding-left:0px;
        li
        {
          padding-left: 0px;
          background-image: inherit;
          position: relative;
          line-height: 18px;
          font-size: 18px;
          font-weight: 600;
          font-family: ${font('secondary', 0)};
          margin-bottom: 20px;
          padding-bottom: 5px;
          &:last-child
          {
            margin-bottom: 0px; 
          }
          &::before{
            content:'\\00b7';
            //content:url(${icon_circle_01});
            width: 6px;
            height: 6px;
            font-size: 36px;
            color: ${palette('colorBulletTabs', 0)};
            display: inline-block;
            border-radius: 50%;
            vertical-align: middle;
            position: absolute;
            left: -27px;
            top: 0px;
          }
          &::after{
            content:' ';
            width: 1px;
            background-color: ${palette('colorGray2', 0)};
            height: 145%;
            left:-23px;
            top:13px;
            position: absolute;
          }
  
          &:last-child{
            &::after{
              content:none;
            }
          }
  
          a{
            font-family: ${font('secondary', 0)};
            color:${palette('colorTabsVideo', 0)};
          }
          
          @media only screen and (max-width: 575px)
          {
              font-size: 16px;
          }
          @media only screen and (min-width: 576px) and (max-width: 767px)
          {
              font-size: 17px;
          }
        }
  
        li.active-video
        {
          color:${palette('colorLink', 0)};
          a
          {
            color:${palette('colorLink', 0)};
          }
        }
      }

      .ul-info-description{
        padding:0px;
        margin:0px;
        li{
          padding:19px 20px 19px 20px;
          margin: 0px;
          background-image: inherit;
          border-bottom:1px solid ${palette('colorLink', 0)};
          color:${palette('colorTabsVideo', 0)};
          font-weight: 600;
          font-size: 18px;
          font-family: ${font('secondary', 0)};
          line-height: 21px;
          &:last-child
          {
            border-bottom: 0px;
          }
        }
      }

      .ul-bullets
      {
        margin:0px;
        padding:0px;
        padding-left: 8%;
        @media only screen and (max-width: 575px)
        {
          padding-left: 0px;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px)
        {
          padding-left: 0px;
        }
        li
        {
          background-image: inherit;
          position: relative;
          padding-left: 18px;
          margin-bottom: 15px;
          line-height: 1.4em;
          span
          {
              font-weight: 300;
              display: inline-block;
          }
          &::before
          {
              content:'\\00b7';
              font-size: 42px;
              //content:url(${icon_circle_01});
              //width: 5px;
              //height: 5px;
              color: ${palette('colorBulletTabs', 0)};
              display: inline-block;
              border-radius: 50%;
              position: absolute;
              top:0px;
              left:0px;
              line-height: 0.6em;
          }
          &:last-child
          {
              margin-bottom: 0px;
          }
        }
      }

    }

    .content-buttons-flex
    {
      @media only screen and (max-width: 575px)
      {
        text-align: center;
      }
      @media only screen and (min-width: 576px) and (max-width: 767px)
      {
        text-align: center;
      }
    }

    /*Sidebar*/
    .img-sidebar-icon 
    {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }

    /*xxl*/
    @media (min-width: 1600px){

    }

    /*xl*/
    @media only screen and (min-width: 1200px) and (max-width: 1599px) 
    { 
    }

    /*lg*/
    @media only screen and (min-width: 992px) and (max-width: 1199px) { 

    }

    /*md*/
    @media only screen and (min-width: 768px) and (max-width: 991px) { 
  
    }

    /*sm*/
    @media only screen and (min-width: 576px) and (max-width: 767px) { 
      font-size:14px;

      h1
      {
        margin-bottom:18px;
      }
      h2
      {
        font-size:17px;
        margin-bottom:15px;
      }
      h3
      {
        font-size:15px;
        margin-bottom:12px;
        a{
        }
      }
      h4{
        font-size:14px;
        margin-bottom:9px;
      }
      h5
      {
        margin-bottom:9px;
      }
      h6
      {
        margin-bottom:9px;
      }
      p
      {
        margin-bottom:17px;
        &:last-child
        {
          margin-bottom:0px;
        }
      }
      a{
      }
      small
      {
        font-size:14px;
      }

    }

    /*xs*/
    @media only screen and (max-width: 575px) 
    {
      font-size:15px;
      h1
      {
        margin-bottom:15px;
      }
      h2
      {
        font-size:17px;
        margin-bottom:10px;
      }
      h3
      {
        font-size:16px;
        margin-bottom:10px;
        a{
        }
      }
      h4{
        margin-bottom:10px;
      }
      h5
      {
        margin-bottom:8px;
      }
      h6
      {
        margin-bottom:8px;
      }
      p
      {
        margin-bottom:15px;
        &:last-child
        {
          margin-bottom:0px;
        }
      }
      a{
      }
      small
      {
        font-size:13px;
      }
    }
  }
  
  .h2-title
  {
    border-bottom:1px solid ${palette('colorText', 0)};
    padding-bottom:15px;
    position:relative;
    font-size:22px;
    &:after
    {
      content:"";
      width:42px;
      content: "";
      width: 42px;
      height: 4px;
      background-color: ${palette('colorText', 0)};
      display: block;
      position: absolute;
      left: 0;
      bottom: -3px;
    } 

    a{
      color:${palette('colorText', 0)};
      &:hover,&:active,&:focus
      {
        color:${palette('colorText', 0)};
      }
    }

    @media only screen and (max-width: 575px) 
    {
      font-size:19px;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) 
    {
      font-size:20px;
    }

    @media (max-width: 767px) {
      text-align: center;
      img {
        position: absolute;
        left: 0px;
      }
    }

  }

  .h2-title-icon
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button-agregar-respuesta 
  {
    @media only screen and (max-width: 575px)  
    {
      text-align: right;
    }  
  }

  /*antd radio buttons*/
  .ant-radio-inner 
  {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${palette('transparent', 0)};
    border-color: ${palette('colorWhite', 0)};
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: all .3s;
  }
  .ant-radio-wrapper 
  {
    color: ${palette('colorWhite', 0)};
  }
  .ant-radio-checked .ant-radio-inner 
  {
    border-color: ${palette('colorGray3', 0)};
  }
  
  /*antd select*/
  .ant-select 
  {
    color: ${palette('text', 1)};
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
  }
  /*.ant-select:not(.ant-select-customize-input) .ant-select-selector 
  {
    position: relative;
    background-color: transparent;
    border: 1px solid ${palette('colorGray4', 0)};
    border-radius: 2px;
    transition: all .3s cubic-bezier(0.645,0.045,0.355,1);
  }

  .ant-select-multiple .ant-select-selection-item 
  {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 22px;
    background: ${palette('colorWhiteVar2', 0)};
    border: 1px solid ${palette('colorBlackVar1', 0)};
    border-radius: 2px;
    cursor: default;
    transition: font-size .3s,line-height .3s,height .3s;
    user-select: none;
    margin-inline-end: 4px;
    padding-inline-start: 8px;
    padding-inline-end: 4px;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${palette('colorWhiteVar2', 0)};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: ${palette('colorWhiteVar1', 0)};
    font-weight: 600;
    background-color: ${palette('colorBlackVar2', 0)};
  }
  .ant-select-item {
    color: ${palette('colorWhiteVar1', 0)};
  }
  .ant-select-dropdown {
    background-color: ${palette('colorBlackVar3', 0)};
  }*/

  .ant-form-item-explain.ant-form-item-explain-error
  {
      color:${palette('colorLink', 0)};
  }

  .ant-form-item-has-error .ant-input
  {
    border-color: ${palette('colorLink', 0)};
  }

  .profile-link {
    color: ${palette('colorWhite', 0)};
    font-size: 16px;
  }

}

.button-login, button.button-login{
  margin-top:23px;
  font-size:14px;  
  background-color:${palette('bgButtonLogin', 0)};
  border:2px solid ${palette('colorBorderButtonLogin', 0)};
  color:${palette('colorTextButtonLogin', 0)};
  border-radius:30px;
  width:70%;
  margin-left:auto;
  margin-right:auto;
  cursor:pointer;
  height:36px;
  &:focus{
    border:2px solid ${palette('colorWhite', 0)};
    outline:none;
  }
}

button.button-login.ant-btn{
  border-radius:30px;
  font-size:14px;
  cursor:pointer;
  height:36px;
  border:2px solid ${palette('colorBorderButtonLogin', 0)};
  &:focus,&:hover,&:active{
    background-color:${palette('bgButtonLogin', 0)};
    color:${palette('colorTextButtonLogin', 0)};
    border:2px solid ${palette('colorBorderButtonLogin', 0)};
  }
}


.texto-login
{
  color:${palette('colorTextLogoLogin', 0)};
  background-color:${palette('bgTextLogoLogin', 0)};
  text-align:center;
  font-size:20px;
  line-height:30px;
  padding:3px 0px;
  margin-top:3px;
  margin-bottom:0px;
  @media only screen and (min-width: 576px) and (max-width: 767px) 
  {
    font-size:19px;
  }
  @media only screen and (max-width: 575px) 
  {
    font-size:18px;
  }
}

.logoMelian
{
  text-align: center;
  margin-top: 70px;
  @media only screen and (min-width: 576px) and (max-width: 767px) 
  { 
    margin-top:50px;
  }
  @media only screen and (max-width: 575px) 
  {
    margin-top:30px;
  }
}

/* TopbarUser */  //ok
.img-user-profile{
  border:2px solid ${palette('colorText', 0)};
}
.isoImgWrapper {
  svg{
    polygon{
      fill:${palette('colorText', 0)};
    }
  }
}
/* /TopbarUser */

`;

export default GlobalStyles;
