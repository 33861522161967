const actions = {
  GET_RESULTS: 'GET_RESULTS',
  GET_RESULTS_SUCCESS: 'GET_RESULTS_SUCCESS',
  GET_RESULTS_FALSE: 'GET_RESULTS_FALSE',

  getResults: searchText => ({
    type: 'GET_RESULTS',
    searchText: searchText,
  })
}

export default actions;
